<template>
  <v-card
    width="100%"
    :height="displaySize(48, 0.05)"
    color="#4a4f55"
    class="d-flex navCard"
    :class="[`mt-${isMobile ? 3 : 4}`]"
    @click="toAbout(text)"
    :ripple="false"
  >
    <div
      class="ma-2 ml-lg-5"
      id="imgOption"
      :style="{ maxWidth: `${isMobile ? 10 : 7.6}%` }"
    >
      <v-img height="100%" max-width="100%" :src="imageSrc"></v-img>
    </div>
    <!-- <v-img
      class="ma-2 ml-lg-5"
      id="imgOption"
      :max-width="`${isMobile ? 10 : 7.6}%`"
      :src="imageSrc"
    /> -->
    <p
      v-if="text"
      :style="{ fontSize: `${displaySize(1.1, 0.1)}em` }"
      id="navOption"
      class="my-auto ml-xl-14"
      :class="[`ml-${isMobile ? 2 : 4}`]"
    >
      {{ text }}
    </p>
    <v-slider
      v-else
      :height="displaySize(55, 0.15)"
      class="pl-2 pr-6 mr-6"
      :loader-height="10"
      color="black"
      thumb-label
      track-color="black"
    ></v-slider>
  </v-card>
</template>

<script>
export default {
  props: ["imageSrc", "text"],
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    toAbout(type) {
      if (!type) return;
      if (type === "Feedback") {
        this.$store.state.feedbackOpen = true;
        return;
      }
      const routeData = this.$router.resolve({
        name: "about",
        params: { type },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style scoped>
#navOption {
  font-family: Poppins;
  font-style: normal;
  color: white;
  font-weight: 500;
  letter-spacing: 0.12px;
  text-shadow: 0px 0px 20px #ffffff6e;
}
#imgOption {
  filter: drop-shadow(0 5px 0.6rem black);
}
.navCard:hover {
  background: linear-gradient(to right, #9b9b9b, #4a4f55 4%);
}
</style>
