<template>
  <div style="height: 90px">
    <v-card class="d-flex align-center" color="transparent" flat height="98%">
      <v-col align="center" cols="2" class="ma-0 pa-0">
        <img
          :src="
            require(`@/assets/${isMainPage ? 'MoreOption' : 'BackButton'}.svg`)
          "
          class="mt-1"
          :class="[
            isMobile
              ? 'onClick'
              : `${isSafariMAC ? 'safariPrimPlan' : 'primPlan'}`,
            isMainPage || 'px-3',
          ]"
          :height="isMainPage ? '18px' : '20px'"
          :width="isMainPage ? '26px' : '36px'"
          @click="optionBtn"
        />
      </v-col>
      <v-col align="center" cols="8" class="ma-0 pa-0">
        <img
          v-if="isMainPage"
          :src="require('@/assets/CoverFinal.svg')"
          :width="`${displaySize(250, 0.05)}px`"
          height="88px"
      /></v-col>
      <v-col cols="2" align="center" class="ma-0 pa-0">
        <div
          v-if="!$store.getters['isLoading']"
          class="d-flex flex-column align-center"
        >
          <div style="height: 20px"></div>
          <img
            :class="[
              isMobile
                ? 'onClick'
                : `${isSafariMAC ? 'safariPrimPlan' : 'primPlan'}`,
            ]"
            :src="require('@/assets/login.svg')"
            height="32px"
            width="40px"
            @click="
              openAuth(user ? 'TheAccount' : 'LogIn', { deleteUser: false })
            "
          />
          <div
            style="height: 20px"
            :style="{ width: `${isMobile ? 100 : 50}%` }"
          >
            <p
              class="ma-0 account text-truncate"
              :style="{
                fontSize: `${displaySize(0.8, 0.05)}em`,
                letterSpacing: `${isMobile ? 0.5 : 1.5}px`,
                cursor: 'pointer',
              }"
              @click="
                openAuth(user ? 'TheAccount' : 'LogIn', { deleteUser: false })
              "
            >
              {{ user ? user.username : "Login" }}
            </p>
          </div>
        </div>
      </v-col>
    </v-card>
    <hr
      class="mx-auto breakline"
      :style="{
        width: `${isMobile ? 90 : 50}%`,
        minWidth: `${isMobile ? '200' : minWidthBreakLine}px`,
      }"
    />
    <div
      style="position: absolute; z-index: 11; pointer-events: none"
      :style="{
        width: `${isMobile ? '90%' : '50%'}`,
        height: `${isMobile ? '50px' : '100px'}`,
        minWidth: `${isMobile ? '200' : minWidthBreakLine}px`,
        left: `${isMobile ? '5%' : minLeftShadow}`,
        marginTop: `${isSafariMAC && isGamePage ? '4px' : ''}`,
      }"
      class="shadowBreakline fromBottom"
    ></div>
    <div
      v-if="!isGamePage"
      style="position: fixed; z-index: 11; pointer-events: none; bottom: 0%"
      :style="`${
        isMobile
          ? 'width: 100%; height: 50px; min-width: 200px'
          : 'width: 60%; height:100px; left: 20%; min-width: 700px'
      }`"
      class="shadowBreakline"
    ></div>
    <v-navigation-drawer
      style="z-index: 99"
      :width="displaySize(425, 0.15)"
      color="rgb(31.41,33.24,35.06)"
      v-model="drawer"
      temporary
      fixed
    >
      <v-card
        height="100%"
        color="transparent"
        tile
        flat
        class="d-flex flex-column justify-space-between"
      >
        <div class="d-flex flex-column">
          <p
            :style="{ fontSize: `${displaySize(1.5, 0.1)}em` }"
            class="mb-0 mt-4 mInfo"
            :class="[`ml-${isMobile ? 3 : 5}`]"
          >
            Info
          </p>
          <nav-component
            :imageSrc="require('@/assets/rating.svg')"
            :text="'Feedback'"
          ></nav-component>
          <nav-component
            :imageSrc="require('@/assets/rules.svg')"
            :text="'Contest Regulation'"
          ></nav-component
          ><nav-component
            :imageSrc="require('@/assets/termsAndConditions.svg')"
            :text="'Terms and Conditions'"
          ></nav-component>
          <nav-component
            :imageSrc="require('@/assets/privacyPolicy.png')"
            :text="'Privacy Policy'"
          ></nav-component>
          <nav-component
            :imageSrc="require('@/assets/cookie.png')"
            :text="'Cookies Policy'"
          ></nav-component>

          <!-- <p
            :style="{ fontSize: `${displaySize(30, 0.15)}px` }"
            class="mb-0 mt-2 ml-5 mInfo mSettings"
          >
            Settings
          </p>
          <nav-component
            :imageSrc="'https://i.ibb.co/hWQDqKd/download-removebg-preview.png'"
          ></nav-component> -->
        </div>
        <v-footer color="transparent">
          <div
            :style="{ fontSize: `${displaySize(23, 0.12)}px` }"
            class="mInfo"
          >
            Email
          </div>
          <div
            :style="{ fontSize: `${displaySize(18, 0.15)}px` }"
            class="mInfo"
            style="letter-spacing: 1px"
          >
            contact@masterycase.com
          </div>
          <hr class="my-2 breakline" style="width: 100%" />
          <span
            :style="{ fontSize: `${displaySize(14, 0.1)}px`, width: '100%' }"
            class="pFooter mb-1 mb-2"
          >
            <span @click="goExternApp(anpc)" style="cursor: pointer">ANPC</span>
            |
            <span @click="goExternApp(ansp)" style="cursor: pointer"
              >ANSPDCP</span
            >
          </span>
          <img
            :height="displaySize(18, 0.12)"
            src="https://i.ibb.co/72kKWwQ/visa-logo.png"
            class="mr-4"
          />
          <img
            :height="displaySize(18, 0.12)"
            src="https://i.ibb.co/kX63Gpv/Mastercard-logo.png"
          />
          <p
            :style="{ fontSize: `${displaySize(11, 0.1)}px` }"
            class="pFooter mt-3"
          >
            <span class="white--text">&#169;</span> 2022 Soft Reactive
            Development S.R.L. is the creator of Mastery Case.
            <br />
            All rights reserved. Bucharest, No. 12 Decebal Blvd., Room 1,
            Building S7, Entrance 1, Floor 5, Apt. 15, District 3
          </p>
        </v-footer>
        <feedback-dialog
          v-if="this.$store.state.feedbackOpen"
        ></feedback-dialog>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>
import NavComponent from "./NavComponent.vue";
import FeedbackDialog from "./dialogs/FeedbackDialog.vue";

export default {
  props: ["currentPage"],
  components: { NavComponent, FeedbackDialog },
  data: () => ({
    minWidthBreakLine: 700,
    ansp: "https://www.dataprotection.ro/?page=contact&lang=ro",
    anpc: "https://anpc.ro/articol/575/date-contact",
    drawer: false,
  }),
  computed: {
    user() {
      return this.$store.getters["user"];
    },
    isMainPage() {
      return this.currentPage === "main";
    },
    isGamePage() {
      return this.currentPage === "game";
    },
    minLeftShadow() {
      if (this.windowWidth * 0.5 < this.minWidthBreakLine)
        return (this.windowWidth - this.minWidthBreakLine) / 2 + "px";
      return "25%";
    },
  },
  created() {
    if (!this.user.username) this.$store.dispatch({ type: "fetchUser" });
  },
  methods: {
    openAuth(component, props) {
      this.$store.commit("auth/changeComponent", { component, props });
    },
    optionBtn() {
      if (this.isMainPage) this.drawer = true;
      else if (this.$store.getters["previousRoute"].name !== null)
        this.$router.go(-1);
      else this.$router.push({ name: "main" });
    },
  },
};
</script>

<style scoped>
#logo {
  position: absolute;
  left: 40%;
}
#option {
  cursor: pointer;
}

.account {
  color: #9b9b9b;
  font-weight: 700;
}
.mInfo {
  font-family: Poppins;
  color: white;
  letter-spacing: 1.5px;
  width: 100%;
}
.mSettings {
  text-shadow: 0px 0px 16px #0000007d;
  font-weight: 500;
}
.pFooter {
  color: rgba(153, 153, 153, 1);
  text-shadow: 0px 0px 16px #0000007d;
}
.breakline {
  border: 0;
  height: 2px;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    #9b9b9b,
    rgba(255, 255, 255, 0)
  );
}

.shadowBreakline {
  background: radial-gradient(
    65.18% 50% at 50% 100%,
    #000000 0%,
    rgba(0, 0, 0, 0) 88%
  );
  background-blend-mode: darken;
  filter: blur(3px);
}
.fromBottom {
  transform: matrix(1, 0, 0, -1, 0, 0);
}
</style>
