<template>
  <v-dialog
    content-class="round-dialog"
    overlay-opacity="0.7"
    max-width="350px"
    v-model="$store.state.feedbackOpen"
  >
    <v-card
      v-if="!sent"
      class="py-5 d-flex flex-column align-center"
      color="#1d1f20"
    >
      <v-rating
        v-model="review"
        color="yellow darken-3"
        background-color="grey darken-1"
        hover
        large
      ></v-rating>
      <v-text-field
        class="text-body-2 mt-3"
        v-model="content"
        label="Leave us a feedback"
        outlined
        dense
        autofocus
        dark
        :style="{ borderRadius: '10px', width: `${displaySize(300, 0.05)}px` }"
        required
        color="white"
      ></v-text-field>
      <v-btn
        class="btnText rounded-lg my-2"
        style="
          font-size: 100%;
          font-weight: 900;
          text-transform: none;
          letter-spacing: 1px;
        "
        width="25%"
        small
        @click="submit"
        >Submit
      </v-btn>
    </v-card>
    <v-card
      v-else
      class="py-5 rounded-xl d-flex flex-column align-center"
      color="#1d1f20"
      ><p class="regularText text-subtitle-1 text-center font-weight-medium">
        Thank you for your feedback! <br />
        Will take it into account!
      </p>
      <v-btn
        class="btnText rounded-lg my-2"
        style="
          font-size: 100%;
          font-weight: 900;
          text-transform: none;
          letter-spacing: 1px;
        "
        width="25%"
        small
        @click="$store.state.feedbackOpen = false"
        >Ok
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    sent: false,
    review: 1,
    content: "",
  }),

  methods: {
    submit() {
      axios
        .post(
          `${this.$hostname}/feedback`,
          { review: this.review * 2, content: this.content },
          { withCredentials: true }
        )
        .catch(() => {});
      this.sent = true;
      // this.$store.state.feedbackOpen = false;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Poppins:600italic,400,700,500,600");

.v-text-field--outlined >>> label {
  font-size: 13px;
}

.v-text-field--outlined >>> button {
  font-size: 18px;
}

.btnText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  letter-spacing: 1.5px;
  color: #000000;
}

.regularText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 550;
  font-size: 28px;
  letter-spacing: 1px;
  color: #ffffff;
}
</style>